import {Button, Layout, message, Modal, Select, Space, Table, Tag} from "antd";
import {ColumnsType} from "antd/es/table";
import React, {useEffect, useState} from "react";
import Search from "antd/es/input/Search";
import {useDispatch, useSelector} from "react-redux";
import "./ApplyScore.less";
import ApplyScoreInfo from "./ApplyScoreInfo";
import dayjs from "dayjs";

interface DataType {
  key: string
  id: number
  idCardName: string
  idCardNumber: string
  specialityName: string
  leve: number
  code: string
  examiner: string
  hostingResult: HostingResult
  status: string
  fileKey: string
  memo: string
  modified: string
  created: string
}

interface HostingResult {
  judge0: number
  judge1: number
  judge2: number
  judge3: number
  judge4: number
  judge5: number
  judge6: number
  judge7: number
  judge8: number
  passed: string
  unPassed: string
  score: number
}

const ApplyScore = () => {
  const applyScore = useSelector((state: any) => state.applyScore)
  const dispatch = useDispatch()
  const [messageApi, contextMessageHolder] = message.useMessage()

  const onSearch = (value: string) => {
    let request = applyScore.request
    request.keyword = value
    request.pageNum = 1
    request.specialityId = specialityId
    request.companyId = companyId
    request.auditDate = auditDate ? dayjs(auditDate).format("YYYY-MM-DD") : null;
    dispatch.applyScore.setData({request})
  }

  const [deleteId, setDeleteId] = useState<Number>()

  const handleDelete = (record: any) => {
    setDeleteId(record.id)
    setDeleteOpen(true)
  }

  const handleInfo = (record: any) => {
    dispatch.applyScore.setData({openInfo: true, info: record})
  }

  //列
  const columns: ColumnsType<DataType> = [
    {
      title: "编号",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "考试编号",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "姓名",
      dataIndex: "idCardName",
      key: "idCardName",
      sorter: true,
    },
    {
      title: "身份证号",
      dataIndex: "idCardNumber",
      key: "idCardNumber",
    },
    {
      title: "专业",
      dataIndex: "specialityName",
      key: "specialityName",
    },
    {
      title: "等级",
      dataIndex: "level",
      key: "level",
      sorter: true,
      render: (text) => text + "级",
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (text) => (text === '不合格' ? <Tag color="red">{text}</Tag> : <Tag color="green">{text}</Tag>),
    },
    {
      title: "备注",
      dataIndex: "memo",
      key: "memo",
    },
    {
      title: "修改时间",
      dataIndex: "modified",
      key: "modified",
    },
    {
      title: "上传时间",
      dataIndex: "created",
      key: "created",
    },
    {
      title: "操作",
      key: "action",
      render: (_, record) => <OptionBtn record={record}/>
    },
  ]

  const OptionBtn = (props: any) => {
    return (
      <>
        <Button type="link" size="small" onClick={() => handleInfo(props.record)}>
          查看
        </Button>
        <Button type="link" size="small" onClick={() => handleDelete(props.record)}>
          删除
        </Button>
      </>
    )
  }

  //读取列表
  useEffect(() => {
    dispatch.applyScore.page()
  }, [dispatch.applyScore, applyScore.request.keyword, applyScore.request.pageSize, applyScore.request.pageNum,
    applyScore.request.specialityId, applyScore.request.auditDate, applyScore.request.companyId,
    applyScore.request.sorterField,applyScore.request.sorterOrder])

  const [deleteOpen, setDeleteOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const handleDeleteCancel = () => {
    setDeleteOpen(false)
  }

  const handleDeleteOk = () => {
    setConfirmLoading(true)
    dispatch.applyScore.remove({id: deleteId}).then((res: any) => {
      if (res.code === 0) {
        messageApi.success("删除成功")
        dispatch.applyScore.page()
        setDeleteOpen(false)
      } else {
        messageApi.error(res.msg)
      }
      setConfirmLoading(false)
    })
  }

  // 选择专业
  const [specialityId, setSpecialityId] = React.useState<string>('')
  const [specialities, setSpecialities] = React.useState<any[]>([])
  useEffect(() => {
    dispatch.speciality.list({auditType: 'EXAM'}).then((res: any) => {
      let specialities = res.data.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
      setSpecialities(specialities)
    })
  }, [dispatch.speciality])

  //选择机构
  const [companyId, setCompanyId] = React.useState<string>('')
  const [companies, setCompanies] = React.useState<any[]>([])
  useEffect(() => {
    dispatch.company.auditCompanies().then((res: any) => {
      let schools = res.data.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
      setCompanies(schools)
    })
  }, [dispatch.company])

  // 评审日期
  const [dates, setDates] = React.useState<any[]>([])
  const [auditDate, setAuditDate] = React.useState<any>(null);
  useEffect(() => {
    dispatch.examPlan.dates().then((res: any) => {
      let dates = res.data.map((item: any) => {
        return {
          value: item,
          label: item,
        }
      })
      setDates(dates)
    })
  }, [dispatch.examPlan]);

  return (
    <div className={'ApplyScore'}>
      {contextMessageHolder}
      <Modal
        title="特别提醒"
        open={deleteOpen}
        onOk={handleDeleteOk}
        confirmLoading={confirmLoading}
        onCancel={handleDeleteCancel}
        okText="确认"
        cancelText="取消"
      >
        <p>删除数据无法恢复</p>
      </Modal>
      <Layout>
        <Space>
          <Select style={{width: 150}} onChange={(text: string) => setSpecialityId(text)} options={specialities}
                  placeholder="专业"/>
          {/*机构列表，显示自己和下属机构列表*/}
          <Select style={{width: 150}} onChange={(text: string) => setCompanyId(text)} options={companies} placeholder="机构"/>
          <Select style={{width: 150}} onChange={(text: string) => setAuditDate(text)} options={dates} placeholder="测评日期"/>
          <Search placeholder="输入查询内容" enterButton="查询" onSearch={onSearch}/>
        </Space>
        <Layout style={{marginTop: "10px"}}>
          <Table
            columns={columns}
            dataSource={applyScore.data.list}
            pagination={{
              showTotal: (total, range) => `共 ${total} 条, 当前 ${range[0]}-${range[1]} 条`,
              pageSize: applyScore.data.pageSize, // 每页显示的条数
              total: applyScore.data.total, // 数据总条数
              // onChange: (page, pageSize) => {
              //   let request = {...applyScore.request, pageNum: page, pageSize}
              //   dispatch.applyScore.setData({request})
              // },
            }}
            onChange={(pagination, filters, sorter:any, extra) => {
              // console.log(pagination)
              let request = {...applyScore.request, sorterField: sorter.columnKey, sorterOrder: sorter.order,
                pageNum: pagination.current, pageSize: pagination.pageSize}
              dispatch.applyScore.setData({request})
            }}
          />
        </Layout>
      </Layout>

      <ApplyScoreInfo/>
    </div>
  )
}

export default ApplyScore;