import React from 'react';
import {useDispatch} from "react-redux"
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router";

const Oauth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  console.log("token:", token);
  if (token != null) {
    console.log("已经拿到token，去后台登录继续拿到sessionKey");
    dispatch.session.oauth({token}).then((res: any) => {
      if (res.code === 0) {
        //登录成功后，清除url中的code，不能这样，因为我自己也要传参数
        //window.history.replaceState({}, '', window.location.href.split("?")[0]);
        console.log("登录成功");
        navigate('/', {replace: true});//跳转到首页
      }
    });
  }

  return (
    <div>
      <h1>OAuth</h1>
    </div>
  );
}

export default Oauth;