import {Descriptions, Drawer} from "antd";
import {useDispatch, useSelector} from "react-redux";
import global from "../../global";

const ApplyScoreInfo = () => {
  const applyScore = useSelector((state: any) => state.applyScore)
  const dispatch = useDispatch()

  const options = (val: number) => {
    if (val === 1) return "优";
    if (val === 2) return "良";
    if (val === 3) return "中";
    if (val === 4) return "差";
  }

  return (
    <Drawer
      title="查看打分详情"
      width={500}
      open={applyScore.openInfo}
      onClose={() => dispatch.applyScore.setData({openInfo: false})}
      bodyStyle={{paddingBottom: 80}}
    >
      <Descriptions title="" column={1}>
        <Descriptions.Item label="编号">{applyScore.info?.id}</Descriptions.Item>
        <Descriptions.Item label="专业">{applyScore.info?.specialityName}</Descriptions.Item>
        <Descriptions.Item label="等级">{applyScore.info?.level}</Descriptions.Item>
        <Descriptions.Item label="姓名">{applyScore.info?.idCardName}</Descriptions.Item>
        <Descriptions.Item label="证件号码">{applyScore.info?.idCardNumber}</Descriptions.Item>
        <Descriptions.Item label="状态">{applyScore.info?.status}</Descriptions.Item>
        {
          applyScore.info?.specialityName === "主持" && (
            <>
              <Descriptions.Item
                label="状态积极表演欲强">{options(applyScore.info.hostingResult?.judge0)}</Descriptions.Item>
              <Descriptions.Item
                label="声音洪亮气息顺畅">{options(applyScore.info.hostingResult?.judge1)}</Descriptions.Item>
              <Descriptions.Item
                label="吐字清晰字音准确">{options(applyScore.info.hostingResult?.judge2)}</Descriptions.Item>
              <Descriptions.Item
                label="节奏起伏生动分明">{options(applyScore.info.hostingResult?.judge3)}</Descriptions.Item>
              <Descriptions.Item
                label="神态举止自然恰当">{options(applyScore.info.hostingResult?.judge4)}</Descriptions.Item>
              <Descriptions.Item
                label="理解准确情感饱满">{options(applyScore.info.hostingResult?.judge5)}</Descriptions.Item>
              <Descriptions.Item
                label="背诵熟练讲述完整">{options(applyScore.info.hostingResult?.judge6)}</Descriptions.Item>
              <Descriptions.Item
                label="语法规范词句得当">{options(applyScore.info.hostingResult?.judge7)}</Descriptions.Item>
              <Descriptions.Item
                label="不同语体表达准确">{options(applyScore.info.hostingResult?.judge8)}</Descriptions.Item>
              <Descriptions.Item label="意见和建议">{applyScore.info.hostingResult?.passed}</Descriptions.Item>
              {/*<Descriptions.Item label="不通过意见及建议">{applyScore.info.hostingResult?.unPassed}</Descriptions.Item>*/}
              <Descriptions.Item label="总计得分">{applyScore.info.hostingResult?.score}</Descriptions.Item>
            </>
          )
        }
        <Descriptions.Item label="评分表照片">
          {
            applyScore.info.file && <img src={global.getFileOpenUrl(applyScore.info.file)} alt="" style={{width: 150}}/>
          }
        </Descriptions.Item>
        <Descriptions.Item label="备注">{applyScore.info?.memo}</Descriptions.Item>
        <Descriptions.Item label="上传时间">{applyScore.info?.created}</Descriptions.Item>
      </Descriptions>
    </Drawer>
  )
}

export default ApplyScoreInfo