import {createModel} from "@rematch/core";
import {RootModel} from "./index";
import request from "../util/request";

export const applyScore: any = createModel<RootModel>()({
  state: {
    openInfo: false,//打开详情界面
    request: {
      pageNum: 1,
      pageSize: 20,
      keyword: "",
      specialityId: null,
      auditDate: null,
      companyId: null,
      sorterField: '',
      sorterOrder: '',
    },
    info: {},
    data: {
      pageSize: 0,
      total: 0,
      list: [],
    },
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    /**
     * 设置值
     *
     * @param state
     * @param payload
     */
    setData(state, payload) {
      return {...state, ...payload}
    }
  },
  effects: (dispatch) => ({
    /**
     * 分页查询
     * @param payload
     * @param rootState
     */
    async page(payload, rootState) {
      let res = await request.post("/partner/v1/apply/score/page", rootState.applyScore.request);
      if (res.code !== 0) {
        console.log(res.msg);
      } else {
        res.data.list.forEach((item: any) => {
          item.key = item.id
        })
        dispatch.applyScore.setData({data: res.data})
      }
      return res;
    },
  }),
})